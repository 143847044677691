import {parseParams, parseIncludes} from './api';
import {sortArrByDateDesc} from './array';
import {isArray} from './boolean';
import {
  formUtcToLocalDisplayFormat,
  formUtcToLocalISOString,
  getDate,
  getDateByNoOfDays,
  formUtcToLocal,
} from './date';
import {getObjectProp} from './object';
import {propertyFullAddress} from './property';

/**
 * Checks if the viewing date is in future or current.
 *
 * @param {string} dateStr
 * @returns {bool}
 */
export const checkIfViewingIsUpcoming = dateStr => {
  if (!dateStr) {
    return false;
  }
  return formUtcToLocal(dateStr).isSameOrAfter(getDate());
};

/**
 * Creates the api request params for viewings get list api.
 *
 * @param {object} params
 * @returns {string}
 */
export const prepareGetRequestParams = params => {
  const defaults = {
    addfields: parseIncludes([
      'agency',
      'agentdata',
      'belongs_to_agency_admin',
      'property',
      'tenancy_application_url',
    ]),
    include: 'viewing',
    sort: 'viewing.date_time',
    limit: '100',
    ...params,
  };
  return parseParams(defaults);
};

/**
 * Check if viewing can be confirmed
 *
 * @param {object} data
 * @returns {bool}
 */
export const prepareViewingConfirmable = data => {
  const status = getObjectProp(data, 'status');
  const viewingDate = getObjectProp(data, 'viewing.date_time');
  const isInUpcoming24hrs = formUtcToLocal(viewingDate).isBetween(
    getDate(),
    getDateByNoOfDays(1)
  );
  if (isInUpcoming24hrs && status !== 'cancelled' && status !== 'confirmed') {
    return true;
  }
  return false;
};

/**
 * Creates the status of a viewing booking.
 *
 * @param {string} status
 * @returns {string}
 */
export const prepareViewingStatus = data => {
  const attended = !data ? undefined : data.attended;
  const status = getObjectProp(data, 'status');
  const viewingDate = getObjectProp(data, 'viewing.date_time');
  if (attended === 0 && getDate('', true).isAfter(viewingDate)) {
    return 'not_attended';
  }
  if (status === 'cancelled') {
    return 'cancelled';
  }
  if (status === 'confirmed') {
    return 'confirmed';
  }
  return 'booked';
};

/**
 * Prepares the object of viewing details props from the api response.
 *
 * @param {object} data
 * @returns {object}
 */
export const prepareViewingProps = data => {
  const utcDateTime = getObjectProp(data, 'viewing.date_time');
  const agentIsAdmin = getObjectProp(data, 'belongs_to_agency_admin') || false;
  const agentEmail = agentIsAdmin
    ? getObjectProp(data, 'agency.agent_email')
    : getObjectProp(data, 'agentdata.email');
  const agentPhone = agentIsAdmin
    ? getObjectProp(data, 'agency.company_phone')
    : getObjectProp(data, 'agentdata.contact_mobile') ||
      getObjectProp(data, 'agentdata.contact_phone') ||
      '';
  const hasNotification = getObjectProp(data, 'updates') || null;
  const isUpcoming = checkIfViewingIsUpcoming(utcDateTime);

  return {
    address: propertyFullAddress(getObjectProp(data, 'property')),
    agentEmail,
    agencyName: getObjectProp(data, 'agency.company_name_tenancy_application'),
    agentName: getObjectProp(data, 'agentdata.fullname'),
    agentPhone,
    applicationId: getObjectProp(data, 'application_id'),
    id: getObjectProp(data, 'id'),
    isConfirmable: prepareViewingConfirmable(data),
    isUpcoming,
    mainPhotoSrc: getObjectProp(data, 'agency.logo'),
    updates: getObjectProp(data, 'updates') || null,
    hasNotification: !!hasNotification && isUpcoming,
    hasApplication: !!getObjectProp(data, 'application_id'),
    hasApplicationLink: !!getObjectProp(data, 'application_link_sent_at'),
    status: prepareViewingStatus(data),
    scheduledAt: formUtcToLocalDisplayFormat(utcDateTime),
    scheduledAtLocal: formUtcToLocalISOString(utcDateTime),
    scheduledAtUtc: utcDateTime,
    tenancyApplicationUrl: getObjectProp(data, 'tenancy_application_url'),
  };
};

/**
 * Creates the list of viewings to parse as props.
 *
 * @param {array} data
 * @returns {array}
 */
export const prepareViewingListProps = data =>
  (isArray(data) &&
    data.length > 0 &&
    data.map(item => prepareViewingProps(item))) ||
  [];

/**
 * Creates the list of upcoming viewings to parse as props.
 *
 * @param {array} data
 * @returns {array}
 */
export const prepareUpcomingViewingListProps = data =>
  prepareViewingListProps(data).filter(viewing => viewing.isUpcoming);

/**
 * Creates the list of past viewings to parse as props.
 *
 * @param {array} data
 * @returns {array}
 */
export const preparePastViewingListProps = data =>
  sortArrByDateDesc(
    prepareViewingListProps(data).filter(viewing => !viewing.isUpcoming),
    'scheduledAtUtc'
  );

/**
 * Gets the details of status e.g. label, color etc.
 *
 * @param {string} status
 * @param {bool} confirmable
 * @returns {object}
 */
export const prepareViewingStatusDetails = (status, confirmable = false) => {
  const statusMap = {
    booked: {
      color: 'primary',
      label: 'Booked',
    },
    cancelled: {
      color: 'default',
      label: 'Cancelled',
    },
    confirmed: {
      color: 'tertiary',
      label: 'Confirmed',
    },
    confirmable: {
      color: 'secondary',
      label: 'Please Confirm',
    },
    not_attended: {
      color: 'secondary',
      label: 'Did Not Attend',
    },
  };
  return confirmable ? statusMap.confirmable : statusMap[status];
};
