import React, {Suspense} from 'react';
import styled from 'styled-components';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import {useFlag} from 'react-unleash-flags';

import Authentication from './containers/Authentication';
import Loader from './components/Loader';
import Container from './components/Container';
import Header from './components/Header';
import Maintenance from './components/Maintenance';

import HeaderNavFeature from './features/header_nav/HeaderNavFeature';

import * as consts from './utils/constants';
import {flagAllowsUserId, getQueryParam} from './utils/helpers';

const VerifyContainer = React.lazy(() =>
  import('./containers/VerifyContainer')
);

const LoginContainer = React.lazy(() => import('./containers/LoginContainer'));
const PasswordResetRequestContainer = React.lazy(() =>
  import('./containers/PasswordResetRequestContainer')
);

const FullResetAccountRequestContainer = React.lazy(() =>
  import('./containers/FullResetAccountRequestContainer')
);
const RegisterContainer = React.lazy(() =>
  import('./containers/RegisterContainer')
);
const PasswordResetContainer = React.lazy(() =>
  import('./containers/PasswordResetContainer')
);
const FullPasswordResetContainer = React.lazy(() =>
  import('./containers/FullPasswordResetContainer')
);
const NotFound = React.lazy(() => import('./components/NotFound'));
const Dashboard = React.lazy(() => import('./containers/Dashboard'));
const ApplicationsContainer = React.lazy(() =>
  import('./containers/ApplicationsContainer')
);
const ApplicationOverviewContainer = React.lazy(() =>
  import('./containers/ApplicationOverviewContainer')
);
const ApplicantContainer = React.lazy(() =>
  import('./containers/ApplicantContainer')
);
const ApplicationFormContainer = React.lazy(() =>
  import('./containers/ApplicationFormContainer')
);
const SectionApplicationSuccess = React.lazy(() =>
  import('./components/SectionApplicationSuccess')
);
const TenanciesContainer = React.lazy(() =>
  import('./containers/TenanciesContainer')
);
const TenancyDetailsContainer = React.lazy(() =>
  import('./containers/TenancyDetailsContainer')
);
const TenancyFormContainer = React.lazy(() =>
  import('./containers/TenancyFormContainer')
);
const ProfileContainer = React.lazy(() =>
  import('./containers/ProfileContainer')
);
// const Tutorials = React.lazy(() => import('./components/Tutorials'));
const SecurityContainer = React.lazy(() =>
  import('./containers/SecurityContainer')
);
const ViewingContainer = React.lazy(() =>
  import('./containers/ViewingContainer')
);
const ViewingDetailContainer = React.lazy(() =>
  import('./containers/ViewingDetailContainer')
);
const ProfileUpgradeContainer = React.lazy(() =>
  import('./containers/ProfileUpgradeContainer')
);
const ProfileCreditReportsContainer = React.lazy(() =>
  import('./containers/ProfileCreditReportsContainer')
);
const ProfileUpgradeVerifyContainer = React.lazy(() =>
  import('./containers/ProfileUpgradeVerifyContainer')
);

const AboutFeature = React.lazy(() => import('./features/about/AboutFeature'));

const ContactFeature = React.lazy(() =>
  import('./features/contact/ContactFeature')
);

const HomeFeature = React.lazy(() => import('./features/home/HomeFeature'));

const PrivacyFeature = React.lazy(() =>
  import('./features/privacy/PrivacyFeature')
);

const PrivacyPolicyFeature = React.lazy(() =>
  import('./features/privacy_policy/PrivacyPolicyFeature')
);

const RequestDataFeature = React.lazy(() =>
  import('./features/request_data/RequestDataFeature')
);

const StyledApp = styled.div`
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

function App() {
  const maintenanceModeFlag = useFlag(consts.FEATURE_FLAG_MAINTENANCE_MODE);
  const tenantSelfCheckFlag = useFlag(consts.FEATURE_FLAG_TENANT_SELF_CHECK);

  if (maintenanceModeFlag && maintenanceModeFlag.enabled === true) {
    return (
      <Router>
        <StyledApp>
          <Header />
          <Container>
            <Maintenance />
          </Container>
        </StyledApp>
      </Router>
    );
  }

  const url = [window.location.pathname, window.location.search]
    .filter(part => !!part)
    .join('');

  const backUrl = url ? `?back_url=${encodeURIComponent(url)}` : '';

  const loginUrlWithBackUrl = `${consts.SLUG_LOGIN}${backUrl}`;

  return (
    <Router>
      <Authentication>
        {({fetching, verified, isLoggedIn, profile}) => (
          <>
            <StyledApp color={isLoggedIn ? 'light' : 'gradient'}>
              <Header>
                <HeaderNavFeature />
              </Header>
              <Container loggedIn={isLoggedIn}>
                <Suspense fallback={<Loader />}>
                  <Switch>
                    {isLoggedIn && !fetching && verified
                      ? [
                          <Route
                            exact
                            path={consts.SLUG_LOGIN}
                            key="auth_verified_login"
                            component={() => {
                              const r =
                                getQueryParam('back_url') || consts.SLUG_HOME;
                              return <Redirect to={r} />;
                            }}
                          />,
                          <Route
                            exact
                            path={consts.SLUG_APPLICATIONS}
                            key="applications">
                            <ApplicationsContainer />
                          </Route>,
                          <Route
                            exact
                            path={`${consts.SLUG_APPLICATIONS}/complete`}
                            key="application_complete">
                            <SectionApplicationSuccess />
                          </Route>,
                          <Route
                            exact
                            path={`${consts.SLUG_APPLICATIONS}/:id`}
                            key="application_overview">
                            <ApplicationOverviewContainer />
                          </Route>,
                          <Route
                            exact
                            path={`${consts.SLUG_APPLICATIONS}/:id/view`}
                            key="application_view">
                            <ApplicantContainer />
                          </Route>,
                          <Route
                            exact
                            path={`${consts.SLUG_APPLICATIONS}/:id/submit`}
                            key="application_submit">
                            <ApplicationFormContainer />
                          </Route>,
                          <Route
                            exact
                            path={consts.SLUG_TENANCIES}
                            key="agreements">
                            <TenanciesContainer />
                          </Route>,
                          <Route
                            exact
                            path={`${consts.SLUG_TENANCIES}/:id`}
                            key="agreement_view">
                            <TenancyDetailsContainer />
                          </Route>,
                          <Route
                            exact
                            path={`${consts.SLUG_TENANCIES}/:id/sign`}
                            key="agreement_sign">
                            <TenancyFormContainer />
                          </Route>,
                          <Route exact path={consts.SLUG_PROFILE} key="profile">
                            <ProfileContainer />
                          </Route>,
                          <Route
                            exact
                            path={consts.SLUG_SECURITY}
                            key="security">
                            <SecurityContainer />
                          </Route>,
                          ...(flagAllowsUserId(
                            tenantSelfCheckFlag,
                            profile.email
                          )
                            ? [
                                <Route
                                  exact
                                  path={`${consts.SLUG_PROFILE_MY_DATA}`}
                                  key="profile_my_data">
                                  <ProfileUpgradeContainer profile={profile} />
                                </Route>,
                                <Route
                                  exact
                                  path={`${consts.SLUG_PROFILE_CREDIT_REPORTS}/:provider/:id`}
                                  key="credit_reports">
                                  <ProfileCreditReportsContainer />
                                </Route>,
                                <Route
                                  exact
                                  path={`${consts.SLUG_PROFILE_UPGRADE}/:type?`}
                                  key="profile_upgrade_types">
                                  <ProfileUpgradeVerifyContainer />
                                </Route>,
                              ]
                            : []),
                        ]
                      : undefined}
                    {isLoggedIn
                      ? [
                          <Route
                            exact
                            path="/auth/verify/:id/:hash"
                            key="verify_account">
                            <VerifyContainer />
                          </Route>,
                        ]
                      : []}
                    {isLoggedIn && verified === false
                      ? [
                          <Route
                            exact
                            path={consts.SLUG_LOGIN}
                            key="auth_unverified_login"
                            component={() => {
                              const r =
                                getQueryParam('back_url') || consts.SLUG_HOME;
                              return <Redirect to={r} />;
                            }}
                          />,
                          <Route
                            exact
                            key="application_submit_unverified"
                            path={`${consts.SLUG_APPLICATIONS}/:id/submit`}
                            component={() => <Redirect to={consts.SLUG_HOME} />}
                          />,
                        ]
                      : []}
                    {isLoggedIn && !fetching
                      ? [
                          <Route exact path={consts.SLUG_HOME} key="home">
                            <Dashboard />
                          </Route>,
                          <Route
                            exact
                            path="/v"
                            key="viewings_alt"
                            component={() => (
                              <Redirect to={consts.SLUG_VIEWINGS} />
                            )}
                          />,
                          <Route
                            exact
                            path="/v/:id"
                            key="viewing_details_alt"
                            component={({match: {params}}) => {
                              if (params.id) {
                                return (
                                  <Redirect
                                    to={`${consts.SLUG_VIEWINGS}/${params.id}`}
                                  />
                                );
                              }
                              return <NotFound />;
                            }}
                          />,
                          <Route
                            exact
                            path={consts.SLUG_VIEWINGS}
                            key="viewings">
                            <ViewingContainer />
                          </Route>,
                          <Route
                            exact
                            path={`${consts.SLUG_VIEWINGS}/:id`}
                            key="viewing_details">
                            <ViewingDetailContainer />
                          </Route>,
                        ]
                      : undefined}
                    {!isLoggedIn
                      ? [
                          <Route exact path={consts.SLUG_HOME} key="home">
                            <HomeFeature />
                          </Route>,
                          <Route
                            exact
                            path={consts.SLUG_REGISTER}
                            key="register">
                            <RegisterContainer />
                          </Route>,
                          <Route exact path={consts.SLUG_RESET} key="reset">
                            <PasswordResetContainer />
                          </Route>,
                          <Route
                            exact
                            path={consts.SLUG_VERIFY_FULL_RESET}
                            key="verify_reset">
                            <FullPasswordResetContainer />
                          </Route>,
                          <Route
                            exact
                            path={consts.SLUG_FORGOTTEN}
                            key="request_reset">
                            <PasswordResetRequestContainer />
                          </Route>,
                          <Route
                            exact
                            path={consts.SLUG_REQUEST_RESET}
                            key="request_reset">
                            <FullResetAccountRequestContainer />
                          </Route>,
                          <Route
                            exact
                            path="/auth/verify/:id/:hash"
                            key="unauth_verify_account"
                            component={() => (
                              <Redirect to={loginUrlWithBackUrl} />
                            )}
                          />,
                          <Route
                            exact
                            key="unauth_application_submit_unverified"
                            path={`${consts.SLUG_APPLICATIONS}/:id/submit`}
                            component={() => (
                              <Redirect to={loginUrlWithBackUrl} />
                            )}
                          />,
                          <Route
                            exact
                            path={consts.SLUG_VIEWINGS}
                            key="unauth_viewings"
                            component={() => (
                              <Redirect to={loginUrlWithBackUrl} />
                            )}
                          />,
                          <Route
                            exact
                            path={`${consts.SLUG_VIEWINGS}/:id`}
                            key="unauth_viewing_details"
                            component={() => (
                              <Redirect to={loginUrlWithBackUrl} />
                            )}
                          />,
                          <Route
                            exact
                            path={`${consts.SLUG_APPLICATIONS}/:id`}
                            key="unauth_application_overview"
                            component={() => (
                              <Redirect to={loginUrlWithBackUrl} />
                            )}
                          />,
                          <Route
                            exact
                            path={`${consts.SLUG_TENANCIES}/:id`}
                            key="unauth_agreement_view"
                            component={() => (
                              <Redirect to={loginUrlWithBackUrl} />
                            )}
                          />,
                          <Route
                            exact
                            path={consts.SLUG_TENANCIES}
                            key="unauth_agreements"
                            component={() => (
                              <Redirect to={loginUrlWithBackUrl} />
                            )}
                          />,
                          <Route
                            exact
                            path={`${consts.SLUG_TENANCIES}/:id/sign`}
                            key="unauth_agreement_sign"
                            component={() => (
                              <Redirect to={loginUrlWithBackUrl} />
                            )}
                          />,
                          <Route
                            exact
                            path="/v"
                            key="unauth_viewings_alt"
                            component={() => (
                              <Redirect
                                to={`${
                                  consts.SLUG_LOGIN
                                }?back_url=${encodeURIComponent(
                                  consts.SLUG_VIEWINGS
                                )}`}
                              />
                            )}
                          />,
                          <Route
                            exact
                            path="/v/:id"
                            key="unauth_viewing_details_alt"
                            component={({match: {params}}) => {
                              if (params.id) {
                                return (
                                  <Redirect
                                    to={`${
                                      consts.SLUG_LOGIN
                                    }?back_url=${encodeURIComponent(
                                      `${consts.SLUG_VIEWINGS}/${params.id}`
                                    )}`}
                                  />
                                );
                              }
                              return <NotFound />;
                            }}
                          />,
                          <Route exact path={consts.SLUG_LOGIN} key="login">
                            <LoginContainer />
                          </Route>,
                        ]
                      : undefined}
                    <Route exact path={consts.SLUG_ABOUT}>
                      <AboutFeature />
                    </Route>
                    <Route exact path={consts.SLUG_PRIVACY}>
                      <PrivacyFeature />
                    </Route>
                    <Route exact path={consts.URL_PRIVACY_POLICY}>
                      <PrivacyPolicyFeature />
                    </Route>
                    <Route exact path={consts.SLUG_CONTACT}>
                      <ContactFeature />
                    </Route>
                    <Route exact path={consts.SLUG_REQUEST_INFO}>
                      <RequestDataFeature />
                    </Route>
                    {!fetching
                      ? [
                          <Route path="*" key="not_found">
                            <NotFound />
                          </Route>,
                        ]
                      : undefined}
                  </Switch>
                </Suspense>
              </Container>
            </StyledApp>
          </>
        )}
      </Authentication>
    </Router>
  );
}

export default App;
