import React from 'react';
import PropTypes from 'prop-types';
import {motion} from 'framer-motion';
import {Divider} from '@tenancy.nz/design-system';

import StyledMobileNav, {StyledMobileNavItem} from './MobileNav.styled';

function MobileNav({items, open, renderItem}) {
  const variants = {
    divider: {
      hidden: {
        x: -5,
        opacity: 0,
      },
      visible: {
        x: 0,
        opacity: 1,
      },
    },
    list: {
      hidden: {
        transition: {staggerChildren: 0.05, staggerDirection: -1},
      },
      visible: {
        transition: {staggerChildren: 0.07, delayChildren: 0.2},
      },
    },
    item: {
      hidden: {
        x: -50,
        opacity: 0,
      },
      visible: {
        x: 0,
        opacity: 1,
      },
    },
  };

  return (
    <StyledMobileNav
      open={open}
      initial={false}
      animate={open ? 'visible' : 'hidden'}
      variants={variants.list}>
      {items.map((item, i) => (
        <>
          {i !== 0 && (
            <motion.div style={{width: '100%'}} variants={variants.divider}>
              <Divider dense key={`nav-divider-${item.path}`} />
            </motion.div>
          )}
          <StyledMobileNavItem variants={variants.item}>
            {renderItem(item, i)}
          </StyledMobileNavItem>
        </>
      ))}
    </StyledMobileNav>
  );
}

MobileNav.propTypes = {
  /**
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ),

  /**
   */
  open: PropTypes.bool,

  /**
   */
  renderItem: PropTypes.func.isRequired,
};

MobileNav.defaultProps = {
  items: [],
  open: false,
};

export default MobileNav;
