import React, {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useHistory} from 'react-router-dom';
import {
  fetchUserUpgrades,
  upgradeState,
} from 'features/upgrade_products/upgradeProductsSlice';
import {setMessageShow} from '../../features/message/messageSlice';
import {
  fetchProfileAsync,
  tenantState,
} from '../../features/tenant/tenantSlice';
import {fetchViewingsAsync} from '../../features/viewing/viewingSlice';
import {
  checkTokenAsync,
  logoutAsync,
  userState,
} from '../../features/user/userSlice';
import {fetchWithdrawReasonsAsync} from '../../features/withdraw_reason/withdrawReasonSlice';
import {
  API_EMAIL,
  KEY_AUTHENTICATION_TOKEN,
  SLUG_LOGIN,
} from '../../utils/constants';
import {getCookie, isArray, isEmailAddress} from '../../utils/helpers';

function Authentication({children}) {
  const dispatch = useDispatch();
  const {pathname} = useLocation();
  const history = useHistory();
  const auth = useSelector(userState);
  const {profile, pending} = useSelector(tenantState);
  const {lastFetchedUpgrades} = useSelector(upgradeState);

  const doLogout = useCallback(
    () =>
      dispatch(logoutAsync()).then(() => {
        // eslint-disable-next-line fp/no-mutating-methods
        history.push(SLUG_LOGIN);
      }),
    [dispatch, history]
  );

  useEffect(() => {
    // Check if user is Authenticated and logged-in
    dispatch(checkTokenAsync());
  }, [dispatch]);

  useEffect(() => {
    if (!auth.pending && auth.token) {
      const roles = !isArray(auth.roles) ? [] : auth.roles;
      const isTenant =
        roles.includes('Tenant') && isEmailAddress(auth.username) === undefined;
      if (!isTenant) {
        doLogout();
      }
    }
  }, [auth, doLogout]);

  useEffect(() => {
    // If token is not empty and user is logged-in, fetch profile data
    if (!!auth.token && auth.verified !== undefined) {
      const fullProfile = auth.verified === true;
      dispatch(fetchProfileAsync(fullProfile));
    }
    return () => null;
  }, [auth.token, auth.verified, dispatch]);

  React.useEffect(() => {
    let promise;

    if (auth.verified !== undefined && !lastFetchedUpgrades) {
      // eslint-disable-next-line fp/no-mutation
      promise = dispatch(fetchUserUpgrades());
      // eslint-disable-next-line no-console
      promise.unwrap().catch(console.error);
    }

    return () => {
      promise?.abort && promise.abort();
    };
  }, [auth.verified, lastFetchedUpgrades, dispatch]);

  useEffect(() => {
    if (auth.verified !== undefined && profile[API_EMAIL]) {
      dispatch(
        fetchViewingsAsync({
          [`filter[attendee_email]`]: profile[API_EMAIL],
        })
      );
    }
    if (auth.verified === true && profile[API_EMAIL]) {
      dispatch(fetchWithdrawReasonsAsync());
    }
  }, [auth.verified, profile, dispatch]);

  useEffect(() => {
    dispatch(setMessageShow(false));
  }, [dispatch, pathname]);

  useEffect(() => {
    const cookieToken = getCookie(KEY_AUTHENTICATION_TOKEN);
    if (!auth.pending && !!cookieToken && !auth.token) {
      dispatch(checkTokenAsync());
    }
  }, [auth, dispatch, pathname]);

  return (
    <>
      {children({
        fetching: auth.token ? pending : auth.pending,
        verified: auth.verified,
        isLoggedIn: !!getCookie(KEY_AUTHENTICATION_TOKEN) || !!auth.token,
        profile,
      })}
    </>
  );
}

Authentication.propTypes = {
  children: PropTypes.func.isRequired,
};

export default Authentication;
